import { authQuery } from '../config/query.config';
import { notebookApiService } from '@cms/services';

export const notebookApi = notebookApiService(authQuery);
export const {
  useCreateNotebookMutation,
  useGetNotebooksQuery,
  useDeleteNotebookMutation,
  useGetNotebookByIdQuery,
  useUpdateNotebookMutation,
  useTogglePinNotebookMutation,
} = notebookApi;
