import { dataQuery } from '../config/query.config';
import { pollApiService } from '@polls/service';

export const pollApi = pollApiService(dataQuery);

export const {
  useGetPollsQuery,
  useGetPollByIdQuery,
  useCreatePollMutation,
  useUpdatePollMutation,
  useDeletePollMutation,
  useVotePollMutation,
  useGetPollResultsQuery,
  useLazyGetPollResultsQuery,
  useLazyGetPollByIdQuery,
  useEndPollMutation,
} = pollApi;
