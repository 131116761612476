import { createApi } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { authQuery } from '../config/query.config';
import { cookieService } from '@lib/cookie.service';
import { QUERY } from '@constants/query.constants';
import { IImageUploadResponse } from './types';
import {
  IBadgeNormalizedResponse,
  IBadgeQueryParam,
  IBadgeResponse,
  ICollaborationStatus,
  IGetBadgesResponse,
  IGetBadgesResult,
  ILockBadgePayload,
  ILockBadgeResponse,
} from 'app/(app)/dashboard/badges/badges.typings';
import { formatBadgeResponse } from 'app/(app)/dashboard/badges/badges.utils';
import { IBadgePayload, IBadgeUpdatePayload } from 'app/(app)/create-badge/create-badge.types';
import { BadgeType, DATE_FORMATS } from '@ui/constants';
import dayjs from 'dayjs';
import { IGetFanBadgesResponse } from 'app/(app)/fan-profile/fan-profile.typings';

export const badgeAdapter = createEntityAdapter({
  selectId: (item: any) => item.id,
});

export const badgeSelector = badgeAdapter.getSelectors();

export const badgesApi = createApi({
  reducerPath: 'badgesApi',
  baseQuery: authQuery,
  tagTypes: [QUERY.badges, QUERY.badgeItem, QUERY.infiniteBadges],
  endpoints: (builder) => ({
    getBadges: builder.query<IGetBadgesResult, IBadgeQueryParam>({
      query: ({ page, pageSize }) => {
        const userId = cookieService.getUserId();
        return {
          url: `/badges/author/${userId}`,
          params: { page, pageSize },
        };
      },
      transformResponse: (data: IGetBadgesResponse) => {
        return {
          badges: data.badges.map(formatBadgeResponse),
          pagination: {
            pages: data.badge_pages.pages,
          },
        };
      },
      providesTags: [QUERY.badges],
    }),
    infiniteBadges: builder.query<IBadgeNormalizedResponse, IBadgeQueryParam>({
      query: ({ page, pageSize, badgeName, badgeType }) => {
        const userId = cookieService.getUserId();
        return {
          url: `/badges/author/${userId}`,
          params: { page, pageSize, ...(badgeName && { badgeName }), badgeType },
        };
      },
      providesTags: [QUERY.infiniteBadges],
      transformResponse: (data: IGetBadgesResponse) => {
        return {
          badges: badgeAdapter.setAll(
            badgeAdapter.getInitialState(),
            data.badges.map(formatBadgeResponse),
          ),
          pagination: {
            pages: data.badge_pages.pages,
          },
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSamePage = currentArg?.page === previousArg?.page;
        const isSameSearchKeyword = currentArg?.badgeName === previousArg?.badgeName;
        const isSameType = currentArg?.badgeType === previousArg?.badgeType;

        return !isSamePage || !isSameSearchKeyword || isSameType;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) =>
        `${endpointName}-${queryArgs?.badgeName}-${queryArgs?.badgeType}`,
      merge: (currentState, incomingState) => {
        badgeAdapter.addMany(currentState.badges, badgeSelector.selectAll(incomingState.badges));
        currentState.pagination = incomingState.pagination;
      },
    }),
    getBadgeById: builder.query({
      query: (id) => `/badges/${id}?id=${id}`,
      transformResponse: formatBadgeResponse,
      providesTags(result) {
        return [{ type: QUERY.badgeItem, id: result?.id }];
      },
    }),
    deleteBadgeById: builder.mutation<void, string>({
      query: (id) => ({
        url: `/badges/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [QUERY.badges],
    }),
    updateBadgeById: builder.mutation<void, IBadgeUpdatePayload>({
      query: ({ id, expired, sponsors }) => ({
        url: `/badges/${id}`,
        method: 'PATCH',
        body: {
          expired,
          sponsors,
        },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: QUERY.badgeItem, id }],
    }),
    uploadBadgeImage: builder.mutation({
      query: (image: File) => {
        const form = new FormData();
        form.append('file', image);
        return {
          url: '/badge-upload?double_image=true',
          method: 'POST',
          body: form,
          formData: true,
        };
      },
      transformResponse(data: IImageUploadResponse) {
        return data;
      },
    }),
    createBadge: builder.mutation({
      query: (badge: IBadgePayload) => {
        const userId = cookieService.getUserId();

        const claimCodesMap: Record<BadgeType, object> = {
          [BadgeType.Prize]: {
            claim_codes_active: false,
          },
          [BadgeType.Engagement]: {
            claim_codes_active: false,
          },
          [BadgeType.Community]: {
            claim_codes_active: false,
          },
          [BadgeType.Purchase]: {
            claim_codes_active: true,
          },
        };

        return {
          url: `/badges/${badge.id ?? ''}${badge.lock ? `?unlockKey=${badge.lock}` : ''}`,
          method: badge.id ? 'PATCH' : 'POST',
          body: {
            name: badge.name,
            author: userId as string,
            quantity: badge.amount || 0,
            external_url: badge.imageUrl,
            larger_image_url: badge.largeImageUrl,
            availability: badge.availability || 0,
            media_source: badge.platform,
            type: badge.type,
            collaboration_enabled: badge.collaboration,
            draft: badge.draft,
            custom_badge_color: badge.color,
            public_link: badge.publicLink,
            perquisites: badge.perks,
            caption: badge.caption,
            allowedDomain: badge.allowedDomain,
            message: badge.message,
            ...claimCodesMap[badge.type],
          },
        };
      },
      transformResponse: (response: { _id: string }) => {
        return response._id;
      },
      invalidatesTags(id) {
        return [QUERY.badges, { type: QUERY.badgeItem, id }];
      },
    }),
    updateCollaborationStatus: builder.mutation<
      string,
      { id: string; status: ICollaborationStatus }
    >({
      query: ({ id, status }) => {
        return {
          url: `/badges/updateCollaborationStatus/${id}`,
          method: 'PATCH',
          body: {
            status,
          },
        };
      },
      transformResponse: (response: IBadgeResponse) => {
        return response._id;
      },
      invalidatesTags(id) {
        return [QUERY.badges, { type: QUERY.badgeItem, id }];
      },
    }),
    addCollaboration: builder.mutation({
      query(payload) {
        return {
          url: `/badges/collaboration/${payload.id}`,
          method: 'PATCH',
          body: {
            collaboratorId: payload.collaboratorId,
            badgeId: payload.id,
          },
        };
      },
      transformResponse: (response: IBadgeResponse) => {
        return {
          id: response._id,
          name: response.name,
        };
      },
      invalidatesTags(response) {
        if (response && response.id) {
          return [QUERY.badges, { type: QUERY.badgeItem, id: response.id }];
        }
        return [];
      },
    }),
    getBadgeClaimCodes: builder.mutation<{ success: boolean }, { id: string; name: string }>({
      queryFn: async ({ id, name }, api, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: `/claim-codes/${id}`,
          responseHandler: (response) => response.blob() as Blob,
        });
        if (response.data) {
          const hiddenElement = document.createElement('a');
          const url = window.URL || window.webkitURL;
          const blobURL = url.createObjectURL(response.data as Blob);
          hiddenElement.href = blobURL;
          hiddenElement.target = '_blank';
          hiddenElement.download = `KOR-LINK-CODES__${name}__${dayjs().format(
            DATE_FORMATS.iso,
          )}.csv`;
          hiddenElement.click();
          url.revokeObjectURL(blobURL);
          hiddenElement.remove();
          return {
            data: {
              success: true,
            },
          };
        }
        return {
          data: {
            success: false,
          },
        };
      },
    }),
    getFanBadgesInfinite: builder.query({
      query({ creatorId, fanId, page, pageSize }) {
        return {
          url: `/badges/badges-by-company/${fanId}?author=${creatorId}&page=${page}&pageSize=${pageSize}`,
        };
      },
      transformResponse({ byCompany, badge_pages }: IGetFanBadgesResponse) {
        const badges = badgeAdapter.addMany(
          badgeAdapter.getInitialState(),
          byCompany.map(formatBadgeResponse),
        );

        return {
          pages: badge_pages.pages,
          badgeAmount: badge_pages.total_values,
          badges,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameCreator = currentArg.creatorId === previousArg?.creatorId;
        const isSameFan = currentArg.fanId === previousArg?.fanId;
        const isSamePage = currentArg.page === previousArg?.page;
        return !isSameCreator || !isSamePage || !isSameFan;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.creatorId}-${queryArgs.fanId}`;
      },
      merge(currentState, incomingState) {
        badgeAdapter.addMany(currentState.badges, badgeSelector.selectAll(incomingState.badges));
      },
    }),
    lockBadge: builder.mutation<ILockBadgeResponse, ILockBadgePayload>({
      query: ({ id, newLock = false }) => ({
        url: `/lock-badge/${id}`,
        method: 'PUT',
        body: { newLock },
      }),
      transformResponse: (response: ILockBadgeResponse) => response,
      invalidatesTags: (result, error, { id }) => [{ type: QUERY.badgeItem, id }],
    }),
  }),
});

export const {
  useGetBadgesQuery,
  useGetBadgeByIdQuery,
  useLazyGetBadgeByIdQuery,
  useCreateBadgeMutation,
  useUploadBadgeImageMutation,
  useDeleteBadgeByIdMutation,
  useUpdateCollaborationStatusMutation,
  useAddCollaborationMutation,
  useGetBadgeClaimCodesMutation,
  useGetFanBadgesInfiniteQuery,
  useInfiniteBadgesQuery,
  useLazyInfiniteBadgesQuery,
  useUpdateBadgeByIdMutation,
  useLockBadgeMutation,
} = badgesApi;
