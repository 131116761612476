import { createApi } from '@reduxjs/toolkit/query/react';
import { authQuery } from '../config/query.config';
import { cookieService } from '@lib/cookie.service';
import { IPublicProfile, setUser } from '../features/app';
import { IUserResponse, IUserSettingsPatch, ICreatorPublicProfileResponse } from './types';
import { QUERY } from '@constants/query.constants';
import {
  ISocialProfilePayload,
  ISocialProfileResponse,
} from 'app/(app)/dashboard/settings/_components/bio-social-settings/bio-social-settings.typings';
import { IGetFanProfileResponse } from 'app/(app)/fan-profile/fan-profile.typings';
import { IImageUploadResponse } from './types';
import { ICollaborator, ICreateTeamMemberResponse, IUser } from '@ui/components';

export const userApi = createApi({
  reducerPath: 'user-api',
  baseQuery: authQuery,
  tagTypes: [QUERY.user, QUERY.teams, QUERY.collaborators],
  endpoints: (builder) => ({
    getUser: builder.query<IUser, void>({
      query: () => {
        // TODO: prevent undefined
        const userId = cookieService.getUserId();
        if (!userId) {
          return {
            url: '',
          };
        }
        return {
          url: '/users/' + userId,
        };
      },
      transformResponse: (data: IUserResponse) => {
        return {
          ...data,
          avatar: data.avatarURL,
          name: data.companyName,
          displayName: data.displayName,
          email: data.email,
          banner: data.imageURL,
          id: data._id,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {
          return;
        }
      },
      providesTags: [QUERY.user],
    }),
    getPublicProfile: builder.query<IPublicProfile, string>({
      query: (id) => {
        return {
          url: `/users-public-info/${id}`,
        };
      },
      transformResponse: (data: ICreatorPublicProfileResponse) => {
        return {
          avatar: data.avatarURL,
          name: data.companyName,
          displayName: data.displayName,
          banner: data.imageURL,
        };
      },
    }),
    createSocialProfile: builder.mutation<void, ISocialProfilePayload>({
      query(body) {
        return {
          url: '/social-profile',
          method: 'POST',
          body,
        };
      },
    }),
    updateSocialProfile: builder.mutation<void, Partial<ISocialProfilePayload>>({
      query(body) {
        return {
          url: '/social-profile',
          method: 'PATCH',
          body,
        };
      },
    }),
    getSocialProfile: builder.query<ISocialProfilePayload | null, void>({
      query(body) {
        const userId = cookieService.getUserId();
        return {
          url: `/social-profile/${userId}`,
          method: 'GET',
          body,
        };
      },
      transformResponse(response: ISocialProfileResponse | null) {
        return response ? response.socialProfile : null;
      },
    }),
    updatePassword: builder.mutation({
      query({ oldPassword, newPassword }) {
        const userId = cookieService.getUserId();
        return {
          url: `/users/password-reset`,
          method: 'PUT',
          body: {
            id: userId,
            old_password: oldPassword,
            new_password: newPassword,
          },
        };
      },
    }),
    postImage: builder.mutation<string, { height: number; width: number; image: File }>({
      query: ({ height, width, image }) => {
        const form = new FormData();
        form.append('file', image);
        return {
          url: `/image-upload/?height=${height}&width=${width}?double_image=true`,
          method: 'POST',
          body: form,
          formData: true,
        };
      },
      transformResponse(data: IImageUploadResponse) {
        return data.location;
      },
    }),
    updateUserSettings: builder.mutation({
      query(payload: IUserSettingsPatch) {
        const userId = cookieService.getUserId();
        return {
          url: `/update-user-settings/${userId}`,
          method: 'PATCH',
          body: {
            id: userId,
            ...payload,
          },
        };
      },
      invalidatesTags: [QUERY.user],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUser', undefined, (draft) => {
            draft.membershipSettings = args.membershipSettings;
          }),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    generateCSVReport: builder.mutation<void, void>({
      query() {
        const userId = cookieService.getUserId();

        return {
          url: `/public/v1/csv-of-fans/${userId}`,
          method: 'GET',
        };
      },
    }),
    // TODO: move to ai-api after release
    initDataAssistant: builder.query<void, void>({
      query() {
        const userId = cookieService.getUserId();

        return {
          url: `/generate-ai-data/${userId}?badges=true`,
          method: 'POST',
        };
      },
    }),
    getFanProfile: builder.query<IGetFanProfileResponse, string>({
      query(fanId) {
        const userId = cookieService.getUserId();

        return {
          url: `/get-fan-profile/${fanId}?author=${userId}`,
        };
      },
    }),
    getTeamMembers: builder.query<IUser[], void>({
      query() {
        return {
          url: '/teams',
        };
      },
      providesTags: [QUERY.teams],
    }),
    createTeamMembers: builder.mutation<ICreateTeamMemberResponse[], string[]>({
      query(emails) {
        return {
          url: 'teams',
          method: 'POST',
          body: {
            emails,
          },
        };
      },
      invalidatesTags: [QUERY.teams],
      transformResponse(response: { results: ICreateTeamMemberResponse[] }) {
        return response.results;
      },
    }),
    deleteTeamMembers: builder.mutation<void, string[]>({
      query(emails) {
        return {
          url: 'teams',
          method: 'DELETE',
          body: {
            emails,
          },
        };
      },
      invalidatesTags: [QUERY.teams],
    }),
    getCollaborators: builder.query<ICollaborator[], void>({
      query() {
        return {
          url: '/users/collaborators',
        };
      },
      providesTags: [QUERY.collaborators],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetPublicProfileQuery,
  useUpdateUserSettingsMutation,
  useUpdatePasswordMutation,
  useGenerateCSVReportMutation,
  useInitDataAssistantQuery,
  useGetSocialProfileQuery,
  useCreateSocialProfileMutation,
  useUpdateSocialProfileMutation,
  useGetFanProfileQuery,
  usePostImageMutation,
  useGetTeamMembersQuery,
  useCreateTeamMembersMutation,
  useDeleteTeamMembersMutation,
  useGetCollaboratorsQuery,
} = userApi;
