import { createApi } from '@reduxjs/toolkit/query/react';
import { authQuery } from '../config/query.config';
import { cookieService } from '@lib/cookie.service';
import { QUERY } from '@constants/query.constants';
import {
  ICohortResponse,
  ICreateGroupFilterPayload,
  ICreateGroupFilterResponse,
  ICreateGroupPayload,
  IEditGroupPayload,
  IGroup,
  IGroupsResponse,
  IReportGroup,
} from '@ui/components';

export const groupsApi = createApi({
  reducerPath: 'group-api',
  baseQuery: authQuery,
  tagTypes: [QUERY.groups],
  endpoints: (builder) => ({
    getGroups: builder.query<IGroup[], void>({
      query() {
        const userId = cookieService.getUserId();
        return {
          url: `public/v1/cohort?creator_id=${userId}`,
        };
      },
      transformResponse(response: IGroupsResponse) {
        return response.cohorts.map(
          ({ cohort, collaborator, creator_products, filter, ...rest }) => ({
            ...rest,
            name: cohort.name,
            description: cohort.description,
            id: cohort.id,
            filter: filter.filters_list,
            associated_fans: cohort.associated_fans,
            collaborator,
            creator_products,
          }),
        );
      },
      providesTags: [QUERY.groups],
    }),
    removeGroup: builder.mutation<void, string>({
      query(cohortId) {
        return {
          url: `public/v1/cohort/${cohortId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [QUERY.groups],
    }),
    removeCohortFilter: builder.mutation<void, string>({
      query(cohortId) {
        return {
          url: `public/v1/filter/${cohortId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [QUERY.groups],
    }),
    _createFilter: builder.mutation<string, ICreateGroupFilterPayload>({
      query(body) {
        const userId = cookieService.getUserId();

        return {
          url: 'public/v1/filter/',
          method: 'POST',
          body: {
            ...body,
            creator_id: userId,
          },
        };
      },
      transformResponse(response: ICreateGroupFilterResponse) {
        return response.filter.id;
      },
    }),
    createGroup: builder.mutation<void, ICreateGroupPayload>({
      query({ name, description, filters_list }) {
        const userId = cookieService.getUserId();
        return {
          url: 'public/v1/cohort/',
          method: 'POST',
          body: {
            name,
            description,
            creator_id: userId,
            filter_type: 1,
            filters_list,
          },
        };
      },
      invalidatesTags: [QUERY.groups],
    }),
    updateGroup: builder.mutation<void, IEditGroupPayload>({
      query({ id, name, description, filters_list }) {
        const userId = cookieService.getUserId();

        return {
          url: `public/v1/cohort/${id}`,
          method: 'PUT',
          body: {
            name,
            description,
            creator_id: userId,
            filter_type: 1,
            filters_list,
          },
        };
      },
      invalidatesTags: [QUERY.groups],
    }),
    groupReport: builder.mutation<void, IReportGroup>({
      query({ group_id }) {
        return {
          url: `public/v1/group-report/${group_id}`,
          method: 'GET',
        };
      },
      invalidatesTags: [QUERY.groups],
    }),
    getGroupById: builder.query<IGroup, string>({
      query(id) {
        return {
          url: `public/v1/cohort/${id}`,
        };
      },
      transformResponse(response: ICohortResponse) {
        return {
          name: response.cohort.name,
          description: response.cohort.description,
          id: response.cohort.id,
          filter: response.filter.filters_list,
          associated_fans: response.cohort.associated_fans,
        };
      },
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useCreateGroupMutation,
  useRemoveGroupMutation,
  useUpdateGroupMutation,
  useGroupReportMutation,
  useGetGroupByIdQuery,
} = groupsApi;
